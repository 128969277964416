import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './404.less';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="four-oh-four">
      <Header as="h1">
        <span className="red">404:</span> Page not found
      </Header>
      <p>Oh no! You just hit a route that doesn&#39;t exist.</p>
      <p>Contact us at support@heynode.com if you have any questions.</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
